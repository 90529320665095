import PropTypes from 'prop-types'

import InfoSection from 'components/ui/InfoSection'
import Table from 'components/ui/Table'
import RichTextSlice from 'components/ui/RichTextSlice'
import ParallaxImage from 'components/motion/ParallaxImage'
import VideoSlice from 'components/ui/VideoSlice'
import ImageSlice from 'components/ui/ImageSlice'

const ParallaxImageSlice = ({ data, ...props }) => ParallaxImage({ image: data, ...props })

ParallaxImageSlice.propTypes = { data: PropTypes.object }

const componentsByType = {
  parallax_image: ParallaxImageSlice,
  table: Table,
  text: RichTextSlice,
  info_section: InfoSection,
  video: VideoSlice,
  image: ImageSlice,
}

const Slice = ({ type, data }) => componentsByType?.[type]?.({ data }) || null

Slice.propTypes = {
  type: PropTypes.oneOf(Object.keys(componentsByType)),
}

export default Slice
