import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'

import { RichText } from 'prismic'
import { Row, Column12 } from 'components/ui/Grid'
import { Section, Container } from 'components/layout'
import SplitText from 'components/motion/SplitText'
import ViewportEnter from 'components/motion/ViewportEnter'
import { Headline2, Subtitle, BodyCopy } from 'components/ui/Typography'
import Button from 'components/ui/Button'
import ThemeWaypoint from 'components/ui/ThemeWaypoint'
import { TransitionState } from 'gatsby-plugin-transition-link'

import s from './InfoSection.module.scss'
const cn = classNames.bind(s)

const InfoSection = ({ data, hideOnMobile = false, waypoint, contentFullWidth = false }) => {
  const [isSectionInView, setIsSectionInView] = useState(false)
  const [isInView, setIsInView] = useState(false)
  const [isContentInView, setIsContentInView] = useState(false)
  if (!data?.primary) return null
  const { title, subtitle, content, link, link_text } = data?.primary || {}

  return (
    <TransitionState>
      {({ mount, transitionStatus, current } = {}) => (
        <div className={cn({ isSectionInView, [transitionStatus]: transitionStatus })}>
          {waypoint && <ThemeWaypoint after={waypoint} className={cn('waypoint')} />}
          <Section
            hideOnMobile={hideOnMobile}
            onEnter={() => setIsSectionInView(true)}
            onExit={() => setIsSectionInView(false)}
            once={false}
          >
            <Container>
              <Row>
                <Column12 initial={10} medium={8} large={6} center>
                  <ViewportEnter onEnter={() => setIsInView(true)}>
                    <div className={cn('wrapper', { inView: isInView })}>
                      {subtitle && (
                        <div className={cn('subtitle')}>
                          <Subtitle vertical className={isInView ? 'animate' : ''}>
                            {subtitle[0].text}
                          </Subtitle>
                        </div>
                      )}
                      {title[0].text && (
                        <div className={cn('headline')}>
                          <Headline2>
                            <SplitText>{title[0].text}</SplitText>
                          </Headline2>
                        </div>
                      )}
                      {content && (
                        <ViewportEnter onEnter={() => setIsContentInView(true)}>
                          <div className={cn('content', { animate: isContentInView })}>
                            <Row>
                              <Column12 desktop={contentFullWidth ? 12 : 6} right>
                                <BodyCopy as="div" className={cn('copy')}>
                                  {RichText.render(content)}
                                </BodyCopy>
                              </Column12>
                            </Row>
                          </div>
                        </ViewportEnter>
                      )}
                      {link && (
                        <div className={cn('btn')}>
                          <Button to={link.url} isBold>
                            {link_text}
                          </Button>
                        </div>
                      )}
                    </div>
                  </ViewportEnter>
                </Column12>
              </Row>
            </Container>
          </Section>
        </div>
      )}
    </TransitionState>
  )
}

InfoSection.propTypes = {
  data: PropTypes.object,
  hideOnMobile: PropTypes.bool,
  waypoint: PropTypes.oneOf(['light', 'dark']),
  contentFullWidth: PropTypes.bool,
}

export default InfoSection
