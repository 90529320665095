import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { RichText } from 'prismic'

import { Row, Column12 } from 'components/ui/Grid'

import { Headline3, Headline4, BodyCopy } from 'components/ui/Typography'

import s from './Table.module.scss'
const cn = classNames.bind(s)

const slugify = (str) => {
  return str
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}

const Table = ({ data, className, ...props }) => {
  const { primary, fields } = data
  return (
    <div id={slugify(primary?.table_title || '')} className={cn('table', className)} {...props}>
      <Row>
        <Column12 initial={10} center>
          <div className={cn('header')}>
            {primary?.table_title && <Headline3 className={cn('tableHeading')}>{primary?.table_title}</Headline3>}
            {primary?.table_description && (
              <BodyCopy className={cn('tableDescription')}>{primary?.table_description}</BodyCopy>
            )}
          </div>
        </Column12>
      </Row>
      {fields?.map((c, i) => (
        <Row key={i}>
          <Column12 initial={10} center className={cn('tableRow')}>
            <div className={cn('columnTitle')}>
              <Headline4>{c.column_title}</Headline4>
            </div>
            <div className={cn('columnBody')}>
              <RichText render={c.column_body} />
            </div>
            <div className={cn('columnInfo')}>{c.column_info}</div>
          </Column12>
        </Row>
      ))}
    </div>
  )
}

Table.propTypes = {
  className: PropTypes.string,
}

export default Table
