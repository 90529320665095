import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { TransitionState } from 'gatsby-plugin-transition-link'

import SEO from 'components/seo'
import Layout from 'layouts'

import Spacer from 'components/ui/Spacer'
import Slice from 'components/ui/Slice'
import InfoSection from 'components/ui/InfoSection'

const GenericPage = ({ data: { prismic }, ...props }) => {
  const { page } = prismic
  const metaTitle = page?.page_meta_title
  const metaDescription = page?.page_meta_description
  const metaThumbnail = page?.page_meta_thumbnail
  const schema = page?.page_schema

  return (
    <>
      <TransitionState>
        {({ mount, transitionStatus, current: { state } }) => {
          return (
            <Layout lightTheme>
              <SEO
                title={metaTitle}
                description={metaDescription}
                thumbnail={metaThumbnail}
                schema={schema}
                lightTheme
              />
              {page?.body?.map(({ type, ...data }, index) => (
                <Fragment key={index}>
                  {index < 1 && <Spacer initial="8.6255rem" desktop="17vw" />}
                  {index > 0 && <Spacer />}

                  {type === 'info_section' ? (
                    <InfoSection contentFullWidth data={data} />
                  ) : (
                    <Slice type={type} data={data} />
                  )}
                </Fragment>
              ))}
            </Layout>
          )
        }}
      </TransitionState>
    </>
  )
}

export default GenericPage

export const query = graphql`
  query GenericPageQuery($uid: String!) {
    prismic {
      page: generic_page(uid: $uid, lang: "en-us") {
        generic_page_title
        page_meta_title
        page_meta_description
        page_schema
        page_meta_thumbnail
        body {
          ... on PRISMIC_Generic_pageBodyInfo_section {
            type
            label
            primary {
              title
              subtitle
              content
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
              link_text
            }
          }
          ... on PRISMIC_Generic_pageBodyParallax_image {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2000, srcSetBreakpoints: [375, 500, 1000, 1200, 1400]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_Generic_pageBodyTable {
            type
            label
            primary {
              table_description
              table_title
            }
            fields {
              column_body
              column_info
              column_title
            }
          }
          ... on PRISMIC_Generic_pageBodyText {
            type
            primary {
              text
            }
          }
          ... on PRISMIC_Generic_pageBodyImage {
            type
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 2000, srcSetBreakpoints: [375, 500, 1000, 1200, 1400]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          ... on PRISMIC_Generic_pageBodyVideo {
            type
            primary {
              embed_id
              embed_platform
              ratio
            }
          }
        }
        _linkType
        _meta {
          uid
        }
      }
    }
  }
`
